import React from "react";
import { useTranslation } from "react-i18next";

import NavBar from "../NavBar/NavBar";
import { Link } from "react-router-dom";
import { IMAGE_BASE_URL } from "../../config/Config";
import ReactPlayer from "react-player";

const photo_files = Array.from(Array(11), (x, i) => i + 1).map(
  (num) => `figure_skating_${num.toString().padStart(3, "0")}.jpg`,
);

const FigureSkating = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <NavBar navBarShrink={"navbar-shrink"} />
      <div className="main-content container">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/services">{t("common:services")}</Link>
            </li>
            <li className="breadcrumb-item">{t("common:figure-skating")}</li>
          </ol>
        </nav>
        <div className="row">
          <div className="col-12">
            <h2 className="text-center">{t("common:figure-skating")}</h2>
            <p>{t("common:figure-skating-description")}</p>
          </div>
        </div>
        <section id="figure-skating-training-videos">
          <div className="container">
            <div className="section-heading text-center">
              <h2>{t("common:videos")}</h2>
              <hr />
            </div>
            <div className="row">
              <div className="col-12 d-flex justify-content-center mt-3 mb-3">
                <ReactPlayer url={`https://www.youtube.com/watch?v=GtxahstoD_s`} controls={true} />
              </div>
            </div>
          </div>
        </section>
        <section id="figure-skating-training-photos">
          <div className="container">
            <div className="section-heading text-center">
              <h2>{t("common:photos")}</h2>
              <hr />
            </div>
            <div className="row">
              <div className="col-12">
                {photo_files.map((photo_file) => (
                  <div className="text-center">
                    <img
                      key={photo_file}
                      src={`${IMAGE_BASE_URL}/figure_skating/${photo_file}`}
                      className="img-fluid pb-3"
                      alt={`${photo_file}`}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};

export default FigureSkating;
