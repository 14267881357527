import React from "react";
import { useTranslation } from "react-i18next";

import NavBar from "../NavBar/NavBar";
import summerCamps from "./summer-camps.json";
import "./style.css";
import { Link } from "react-router-dom";

const CampsPage = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <NavBar navBarShrink={"navbar-shrink"} />
      <div className="main-content container">
        <div className="row">
          <div className="col-12">
            <h2 className="text-center mb-3 pb-3">{t("common:summer-camp")}</h2>
            {summerCamps.map((camp, index0) => (
              <React.Fragment key={index0}>
                <div className={`row border-left border-top border-right ${!camp.ageGroup ? "border-bottom" : ""}`}>
                  <div className="col-12 col-lg-2 text-center align-self-center">
                    <div className="row">
                      <div className="col-12">
                        <div>
                          <strong>{camp.campName}</strong>
                        </div>
                        {camp.ageGroup && (
                          <div>
                            <strong>{`AGE ${camp.ageGroup}`}</strong>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-8">
                    {index0 === 0 && (
                      <div className="row">
                        <div className="col-3 border-left border-bottom">
                          <strong>{t("common:date")}</strong>
                        </div>
                        <div className="col-3 border-left border-bottom">
                          <strong>{t("common:weekday")}</strong>
                        </div>
                        <div className="col-3 border-left border-bottom">
                          <strong>{t("common:start-time")}</strong>
                        </div>
                        <div className="col-3 border-left border-right border-bottom">
                          <strong>{t("common:end-time")}</strong>
                        </div>
                      </div>
                    )}

                    {camp.dateTimes.map((campDateTime, index1) => (
                      <div className="row" key={index1}>
                        <div
                          className={`col-3 border-left ${index1 < camp.dateTimes.length - 1 ? "border-bottom" : ""}`}
                        >
                          {campDateTime.date}
                        </div>
                        <div
                          className={`col-3 border-left ${index1 < camp.dateTimes.length - 1 ? "border-bottom" : ""}`}
                        >
                          {campDateTime.weekend}
                        </div>
                        <div
                          className={`col-3 border-left ${index1 < camp.dateTimes.length - 1 ? "border-bottom" : ""}`}
                        >
                          {campDateTime.startTime}
                        </div>
                        <div
                          className={`col-3 border-left border-right ${
                            index1 < camp.dateTimes.length - 1 ? "border-bottom" : ""
                          }`}
                        >
                          {campDateTime.endTime}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="col-12 col-lg-2 text-center align-self-center">
                    {camp.ageGroup && (
                      <Link to={{ pathname: `/registration` }} className="mt-3">
                        <button className="btn btn-primary">{t("common:registration")}</button>
                      </Link>
                    )}
                  </div>
                </div>
                {!camp.ageGroup && <br />}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CampsPage;
