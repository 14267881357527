import React, { Component } from "react";

import NavBar from "../NavBar/NavBar";
import Hero from "../Hero/Hero";
import Services from "../Services/Services";

import "./style.css";
import Faq from "../Faq/Faq";

type HomeProps = {};

type HomeState = {
  navBarShrink: string;
};

class Home extends Component<HomeProps, HomeState> {
  constructor(props: HomeProps) {
    super(props);
    this.state = { navBarShrink: "" };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.handleScroll();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll() {
    // const domNode = ReactDOM.findDOMNode(this.navEl);
    const nbs = window.pageYOffset > 100 ? "navbar-shrink" : "";
    this.setState({ navBarShrink: nbs });
  }

  render() {
    // const nbs = this.state ? this.state.navBarShrink : "";
    return (
      <React.Fragment>
        <NavBar navBarShrink={"navbar-shrink"} />
        {/* <NavBar navBarShrink={nbs}  /> */}
        <Hero />
        <Services />
        <Faq />
      </React.Fragment>
    );
  }
}

export default Home;
