import React from "react";
import { useTranslation } from "react-i18next";

import NavBar from "../NavBar/NavBar";

import { Link } from "react-router-dom";
import { IMAGE_BASE_URL } from "../../config/Config";

const AffiliatedSites = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <NavBar navBarShrink={"navbar-shrink"} />
      <div className="main-content container">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/resources">{t("common:resources")}</Link>
            </li>
            <li className="breadcrumb-item" aria-current="page">
              {t("common:affiliated-sites")}
            </li>
          </ol>
        </nav>
        <React.Fragment>
          <h3 className="mt-3 pt-3">{t("common:affiliated-sites")}</h3>
          <div className="row pt-3">
            <div className="col-12">
              <img
                src={`${IMAGE_BASE_URL}/affiliated_sites/talkers.png`}
                className="card-img-top mr-3"
                alt={`talkers`}
                style={{ height: "64px", width: "64px" }}
              />
              <a href="https://www.parkerenglishdrills.com/" rel="noopener noreferrer" target="_blank">
                Parker English Drills
              </a>
            </div>
          </div>
        </React.Fragment>
      </div>
    </React.Fragment>
  );
};

export default AffiliatedSites;
