import React from "react";
import { IErrors, IFormContext, FormContext, IValues } from "./Form";

type Editor = "textbox" | "multilinetextbox" | "dropdown" | "checkbox";

export interface IValidation {
  rule: (values: IValues, fieldName: string, args: any) => string;
  args?: any;
}

export interface IFieldProps {
  id: string;
  label?: string;
  editor?: Editor;
  options?: { id: string; label: string }[];
  value?: any;
  required?: boolean;
  validations?: IValidation[];
}

export const Field: React.SFC<IFieldProps> = ({ id, label, editor, options, value, required }) => {
  const getError = (errors: IErrors): string[] => (errors ? errors[id] : []);
  const getEditorStyle = (errors: IErrors): any =>
    getError(errors) && getError(errors).length > 0 ? { borderColor: "red" } : {};
  return (
    <FormContext.Consumer>
      {(context: IFormContext | undefined) => (
        <div className="form-group">
          {label && (
            <label htmlFor={id}>
              {label}
              {required && (
                <span className="ml-1" style={{ color: "red" }}>
                  *
                </span>
              )}{" "}
            </label>
          )}

          {editor!.toLowerCase() === "textbox" && (
            <input
              id={id}
              type="text"
              value={value}
              style={getEditorStyle(context!.errors)}
              onChange={(e: React.FormEvent<HTMLInputElement>) => context!.setValues({ [id]: e.currentTarget.value })}
              onBlur={() => context!.validate(id)}
              className="form-control"
            />
          )}

          {editor!.toLowerCase() === "checkbox" && (
            <>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={value}
                  onChange={(e: React.FormEvent<HTMLInputElement>) =>
                    context!.setValues({ [id]: e.currentTarget.value })
                  }
                  id={id}
                />
                <label className="form-check-label">{label}</label>
              </div>
            </>
          )}

          {editor!.toLowerCase() === "multilinetextbox" && (
            <textarea
              id={id}
              value={value}
              style={getEditorStyle(context!.errors)}
              onChange={(e: React.FormEvent<HTMLTextAreaElement>) =>
                context!.setValues({ [id]: e.currentTarget.value })
              }
              onBlur={() => context!.validate(id)}
              className="form-control"
            />
          )}

          {editor!.toLowerCase() === "dropdown" && (
            <select
              id={id}
              name={id}
              value={value}
              style={getEditorStyle(context!.errors)}
              onChange={(e: React.FormEvent<HTMLSelectElement>) => context!.setValues({ [id]: e.currentTarget.value })}
              onBlur={() => context!.validate(id)}
              className="form-control"
            >
              <option>{"Please select one camp"}</option>
              {options &&
                options.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.label}
                  </option>
                ))}
            </select>
          )}

          {getError(context!.errors) && getError(context!.errors).length > 0 && (
            <div style={{ color: "red", fontSize: "80%" }}>
              {getError(context!.errors).map((error, index) => (
                <p key={index}>{error}</p>
              ))}
            </div>
          )}
        </div>
      )}
    </FormContext.Consumer>
  );
};

Field.defaultProps = {
  editor: "textbox",
  required: false,
};
