import React from "react";
import { useTranslation } from "react-i18next";

import NavBar from "../NavBar/NavBar";

import Picture from "./Picture";
import { Link } from "react-router-dom";

import { Equipment } from "./Interfaces";

const HockeyEquipments = () => {
  const { t } = useTranslation();
  const equipments = t("data:equipments", { returnObjects: true }) as {
    skater: Equipment[];
    goalie: Equipment[];
  };
  return (
    <React.Fragment>
      <NavBar navBarShrink={"navbar-shrink"} />
      <div className="main-content container">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/resources">{t("common:resources")}</Link>
            </li>
            <li className="breadcrumb-item" aria-current="page">
              {t("common:hockey-equipments")}
            </li>
          </ol>
        </nav>
        <React.Fragment>
          <h3 className="mt-3 pt-3">{t("common:hockey-equipments")}</h3>
          <h4>
            {t("common:skater")} {t("common:equipment")}
          </h4>
          <table className="table">
            <thead className="thead-dark">
              <tr>
                <th scope="col">#</th>
                <th scope="col">{t("common:equipment")}</th>
                <th scope="col">{t("common:picture")}</th>
              </tr>
            </thead>
            <tbody>
              {equipments.skater.map((equipment, index) => (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <th>{`${equipment.name}`}</th>
                  <th>{equipment.picture && <Picture name={equipment.name} picture={equipment.picture} />}</th>
                </tr>
              ))}
            </tbody>
          </table>
          <h4>
            {t("common:goalie")} {t("common:equipment")}
          </h4>
          <table className="table">
            <thead className="thead-dark">
              <tr>
                <th scope="col">#</th>
                <th scope="col">{t("common:equipment")}</th>
                <th scope="col">{t("common:picture")}</th>
              </tr>
            </thead>
            <tbody>
              {equipments.goalie.map((equipment, index) => (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <th>{`${equipment.name}`}</th>
                  <th>{equipment.picture && <Picture name={equipment.name} picture={equipment.picture} />}</th>
                </tr>
              ))}
            </tbody>
          </table>
        </React.Fragment>
      </div>
    </React.Fragment>
  );
};

export default HockeyEquipments;
