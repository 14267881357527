import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

import { Equipment } from "./Interfaces";

import "./style.css";
import { IMAGE_BASE_URL } from "../../config/Config";

const transition = {
  type: "spring",
  damping: 25,
  stiffness: 120,
};

const Picture = ({ name, picture }: Equipment) => {
  const [isZoomed, setIsZoomed] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => isZoomed && setIsZoomed(false));
    return () => window.removeEventListener("scroll", () => isZoomed && setIsZoomed(false));
  });
  return (
    <div className={`image-container ${isZoomed ? "open" : ""} `}>
      <motion.div
        animate={{ opacity: isZoomed ? 1 : 0 }}
        transition={transition}
        className="shade"
        onClick={() => setIsZoomed(false)}
      />
      <motion.img
        src={`${IMAGE_BASE_URL}/hockey_equipments/${picture}`}
        alt={name}
        onClick={() => setIsZoomed(!isZoomed)}
        layoutTransition={transition}
      />
    </div>
  );
};

export default Picture;
