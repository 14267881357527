import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Home from "../Home/Home";
import NotFound from "../NotFound/NotFound";

import "./style.css";
import ServicesPage from "../Services/ServicesPage";
import AboutUsPage from "../AboutUs/AboutUsPage";
import FaqPage from "../Faq/FaqPage";
import ContactUsPage from "../ContactUs/ContactUsPage";
import HockeyEquipments from "../Resources/HockeyEquipments";
import HockeyEquipmentsConsultingService from "../Services/HockeyEquipmentsConsultingService";
import HockeyRules from "../Resources/HockeyRules";
import HockeyTraining from "../Services/HockeyTraining";
import FigureSkating from "../Services/FigureSkating";
import HockeyExchange from "../Services/HockeyExchange";
import LearnToPlay from "../Services/LearnToPlay";
import SkillsDevelopment from "../Services/SkillsDevelopment";
import TaticsDevelopment from "../Services/TaticsDevelopment";
import OffIceTraining from "../Services/OffIceTraining";
import ResourcesPage from "../Resources/ResourcesPage";
import AffiliatedSites from "../Resources/AffiliatedSites";
import CampsPage from "../Camps/CampsPage";
import RegistrationPage from "../Registration/RegistrationPage";

const Main = () => {
  return (
    <main>
      <Switch>
        <Redirect exact from="/" to="/home" />
        <Route exact path="/home" component={Home} />
        <Route exact path="/about-us" component={AboutUsPage} />
        <Route exact path="/services" component={ServicesPage} />
        <Route exact path="/services/hockey-training" component={HockeyTraining} />
        <Route
          exact
          path="/services/hockey-equipments-consulting-service"
          component={HockeyEquipmentsConsultingService}
        />
        <Route exact path="/services/hockey-training/learn-to-play" component={LearnToPlay} />
        <Route exact path="/services/hockey-training/skills-development" component={SkillsDevelopment} />
        <Route exact path="/services/hockey-training/tactics-development" component={TaticsDevelopment} />
        <Route exact path="/services/off-ice-training" component={OffIceTraining} />
        <Route exact path="/services/figure-skating" component={FigureSkating} />
        <Route exact path="/services/hockey-exchange" component={HockeyExchange} />
        <Route exact path="/faq" component={FaqPage} />
        <Route exact path="/camps" component={CampsPage} />
        <Route exact path="/registration" component={RegistrationPage} />
        <Route exact path="/resources" component={ResourcesPage} />
        <Route exact path="/resources/ice-hockey-rules" component={HockeyRules} />
        <Route exact path="/resources/hockey-equipments" component={HockeyEquipments} />
        <Route exact path="/resources/affiliated-sites" component={AffiliatedSites} />
        <Route exact path="/contact-us" component={ContactUsPage} />
        <Route path="/*" component={NotFound} />
      </Switch>
    </main>
  );
};

export default Main;
