import React from "react";

import { useTranslation } from "react-i18next";

import "./style.css";

import NavBar from "../NavBar/NavBar";
import { IMAGE_BASE_URL } from "../../config/Config";

const photo_files = [
  "about_us_001.jpg",
  "about_us_005.jpg",
  "about_us_002.jpg",
  "about_us_003.jpg",
  "about_us_004.jpg",
];

const AboutUsPage = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <NavBar navBarShrink={"navbar-shrink"} />
      <div className="main-content container">
        <div className="row">
          <div className="col-12">
            <h2 className="text-center mb-3 pb-3">{t("common:about-us")}</h2>
            <p className="mb-3 pb-3">{t("data:about-us.p1")}</p>
            <p className="mb-3 pb-3">{t("data:about-us.p2")}</p>
            <p className="mb-3 pb-3">{t("data:about-us.p3")}</p>
            <p className="mb-3 pb-3">{t("data:about-us.p4")}</p>
            <p className="mb-3 pb-3">{t("data:about-us.p5")}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {photo_files.map((photo_file) => (
              <img
                key={photo_file}
                src={`${IMAGE_BASE_URL}/about_us/${photo_file}`}
                className="img-fluid pb-3"
                alt={`${photo_file}`}
              />
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AboutUsPage;
