import React from "react";
import { useTranslation } from "react-i18next";

import { Service } from "./Interfaces";

import NavBar from "../NavBar/NavBar";
import { Link } from "react-router-dom";
import { IMAGE_BASE_URL } from "../../config/Config";

const photo_files = Array.from(Array(16), (x, i) => i + 1).map(
  (num) => `international_hockey_exchange_${num.toString().padStart(3, "0")}.jpg`,
);

const HockeyExchange = () => {
  const { t } = useTranslation();
  const data = t("data:hockey-exchange", { returnObjects: true }) as Service;

  return (
    <React.Fragment>
      <NavBar navBarShrink={"navbar-shrink"} />
      <div className="main-content container">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/services">{t("common:services")}</Link>
            </li>
            <li className="breadcrumb-item">{t("common:hockey-exchange")}</li>
          </ol>
        </nav>
        <div className="row">
          <div className="col-12">
            <h2 className="text-center">{`${data.name}`}</h2>
            <p className="card-text">{`${data.description}`}</p>
          </div>
        </div>
        <section id="hockey-exchange-photos">
          <div className="container">
            <div className="section-heading text-center">
              <h2>{t("common:photos")}</h2>
              <hr />
            </div>
            <div className="row">
              <div className="col-12 text-center">
                {photo_files.map((photo_file) => (
                  <img
                    key={photo_file}
                    src={`${IMAGE_BASE_URL}/international_hockey_exchange/${photo_file}`}
                    className="img-fluid pb-3"
                    alt={`${photo_file}`}
                  />
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};

export default HockeyExchange;
