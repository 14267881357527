import React from "react";
import { useTranslation } from "react-i18next";

import NavBar from "../NavBar/NavBar";
import { Link } from "react-router-dom";

interface PenaltyTime {
  penalty: string;
  classification: string;
  time: string;
}

interface Penalty {
  name: string;
  description: string;
  list: string[];
}

// interface RuleBook {
//   rules: string[],
//   penalties: {
//     major: Penalty,
//     minor: Penalty,
//     misconduct: Penalty
//   },
//   penaltyTimeChart: PenaltyTime[]
// }

const HockeyRules = () => {
  const { t } = useTranslation();
  const rules = t("data:rules", { returnObjects: true }) as string[];
  const penalties = t("data:penalties", { returnObjects: true }) as Penalty[];
  const penaltyTimes = t("data:penalty-chart", { returnObjects: true }) as PenaltyTime[];
  console.log(rules);
  return (
    <React.Fragment>
      <NavBar navBarShrink={"navbar-shrink"} />
      <div className="main-content container">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/resources">{t("common:resources")}</Link>
            </li>
            <li className="breadcrumb-item" aria-current="page">
              {t("common:hockey-rules")}
            </li>
          </ol>
        </nav>
        <React.Fragment>
          <h3 className="mt-3 pt-3">{t("common:hockey-rules")}</h3>
          <div className="row">
            <div className="col-12">{rules && rules.map((rule, index) => <p key={index}>{rule}</p>)}</div>
          </div>
          <h3 className="mt-3 pt-3">{t("common:penalty")}</h3>
          <div className="row">
            <div className="col-12">
              {penalties &&
                penalties.map((penalty, index) => (
                  <React.Fragment key={index}>
                    <p key={index}>{penalty.name}</p>
                    <p key={index}>{penalty.description}</p>
                    <ul>
                      {penalty.list.map((item, index1) => (
                        <li>{item}</li>
                      ))}
                    </ul>
                  </React.Fragment>
                ))}
            </div>
          </div>
          <h3 className="mt-3 pt-3">{t("common:hockey-penalty-time-chart")}</h3>
          <div className="row">
            <div className="col-12">
              {penaltyTimes &&
                penaltyTimes.map((penaltyTime, index) => (
                  <div className="row">
                    <div className="col-4">{penaltyTime.penalty}</div>
                    <div className="col-4">{penaltyTime.classification}</div>
                    <div className="col-4">{penaltyTime.time}</div>
                  </div>
                ))}
            </div>
          </div>
        </React.Fragment>
      </div>
    </React.Fragment>
  );
};

export default HockeyRules;
