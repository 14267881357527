import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { createStore, applyMiddleware } from "redux";

import reduxThunk from "redux-thunk";
import { createBrowserHistory } from "history";

import rootReducer from "./reducers/index";

import "./index.css";
import App from "./App";

import "./i18n";

import * as serviceWorker from "./serviceWorker";

const createStoreWithMiddleware = applyMiddleware(reduxThunk)(createStore);
const history = createBrowserHistory();
const store = createStoreWithMiddleware(rootReducer(history));

const Root = () => (
  <React.StrictMode>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>
  </React.StrictMode>
);

ReactDOM.render(<Root />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
