import React, { Component } from "react";
import { Events, animateScroll as scroll, scrollSpy } from "react-scroll";

import "./style.css";
import logo_white_background from "./logo_white_background.png";
import { NavLink } from "react-router-dom";

import menus from "./menu.json";
import china_flag from "./china.png";
import canada_flag from "./canada.png";

import { withTranslation, WithTranslation } from "react-i18next";

type NavBarProps = {
  navBarShrink: string;
};

type NavBarState = {
  menuShow: boolean;
};

class NavBar extends Component<NavBarProps & WithTranslation, NavBarState> {
  constructor(props: NavBarProps & WithTranslation) {
    super(props);

    this.state = { menuShow: false };
    this.closeMenu = this.closeMenu.bind(this);
    this.changeLanguage = this.changeLanguage.bind(this);
  }

  componentDidMount() {
    Events.scrollEvent.register("begin", () => {
      this.closeMenu();
    });

    Events.scrollEvent.register("end", function () {});
    scrollSpy.update();
  }

  componentWillUnmount() {
    Events.scrollEvent.remove("begin");
    Events.scrollEvent.remove("end");
  }

  toggleShow() {
    this.setState((prevState) => {
      return { menuShow: !prevState.menuShow };
    });
  }

  closeMenu() {
    if (this.state.menuShow) {
      this.setState({ menuShow: false });
    }
  }

  scrollToTop() {
    scroll.scrollToTop();
  }

  changeLanguage = (lng: string) => {
    this.props.i18n.changeLanguage(lng);
  };

  render() {
    const show = this.state.menuShow ? "show" : "";
    const { t } = this.props;
    return (
      <nav className={`navbar navbar-expand-lg navbar-dark fixed-top ${this.props.navBarShrink}`} id="mainNav">
        <div className="container">
          <NavLink className="navbar-brand" to="/">
            <img src={logo_white_background} className="img-fluid mr-1" alt="MTT566Sports" />
          </NavLink>
          <button
            onClick={this.toggleShow.bind(this)}
            className="navbar-toggler navbar-toggler-right"
            type="button"
            data-toggle="collapse"
            data-target="#navbarResponsive"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="fa fa-bars" />
          </button>
          <div className={`collapse navbar-collapse ${show}`} id="navbarResponsive">
            <ul className="navbar-nav ml-auto">
              {menus.map((menu, index) => (
                <li className="nav-item" key={index}>
                  <NavLink className="nav-link" to={`${menu.path}`}>
                    {t(`common:${menu.name}`)}
                  </NavLink>
                </li>
              ))}
            </ul>
            <span className="ml-3 mr-1 flag" onClick={() => this.changeLanguage("en")}>
              <img src={canada_flag} className="mr-1" style={{ width: "24px", height: "24px" }} alt={`Canada Flag`} />
              EN
            </span>
            <span className="ml-1 flag" onClick={() => this.changeLanguage("zh")}>
              <img src={china_flag} className="mr-1" style={{ width: "24px", height: "24px" }} alt={`China Flag`} />
              中文
            </span>
          </div>
        </div>
      </nav>
    );
  }
}
export default withTranslation()(NavBar);
