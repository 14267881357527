import React from "react";
import { useTranslation } from "react-i18next";

import NavBar from "../NavBar/NavBar";
// import { RegistrationForm } from "./RegistrationForm";
import printableRegistrationForm from "./printable-registration-form.png";
import { IMAGE_BASE_URL } from "../../config/Config";

const RegistrationPage = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <NavBar navBarShrink={"navbar-shrink"} />
      <div className="main-content container">
        <div className="row">
          <div className="col-12 text-center">
            <h2>
              <strong>{t("common:registration-form.title")}</strong>
            </h2>
          </div>
        </div>
        <div className="row mt-3 pt-3">
          <div className="col-12 text-center col-lg-3">
            <a
              href={`${IMAGE_BASE_URL}/registration/2024-personal-registration-form-lessons.pdf`}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={printableRegistrationForm}
                alt="Download registration form"
                title="Download registration form"
              ></img>
            </a>
            <a href={`${IMAGE_BASE_URL}/registration/2024-waiver-and-release.pdf`} target="_blank" rel="noreferrer">
              <img
                src={printableRegistrationForm}
                alt="Download waiver and release form"
                title="Download waiver and release form"
              ></img>
            </a>
          </div>
          <div className="col-12 col-lg-9">
            <h3>{t("common:description")}</h3>
            <ul>
              <li>{t("common:registration-form.description-1")}</li>
              {/* <li>{t("common:registration-form.description-2")}</li>
              <li>{t("common:registration-form.description-3")}</li>
              <li>{t("common:registration-form.description-5")}</li> */}
            </ul>
            <h3 className="mb-3">{t("common:payment-information")}</h3>
            <div className="row border-bottom-0">
              <div className="col-4 border text-center">{t("common:5lessons")}</div>
              <div className="col-4 border text-center">{t("common:10lessons")}</div>
              <div className="col-4 border text-center">{t("common:20lessons")}</div>
            </div>
            <div className="row border-top-0">
              <div className="col-4 border text-center">$220</div>
              <div className="col-4 border text-center">$400</div>
              <div className="col-4 border text-center">$750</div>
            </div>
            {/* <div className="row border-bottom-0">
              <div className="col-3 border text-center">{t("common:warrior-camp")}</div>
              <div className="col-3 border text-center">{t("common:hero-camp")}</div>
            </div>
            <div className="row border-top-0">
              <div className="col-3 border text-center">$300</div>
              <div className="col-3 border text-center">$300</div>
            </div> */}
            <ul className="mt-3">
              <li>
                <div>{t("payment-address-1")}</div>
                <div>{t("payment-address-2")}</div>
                <div>{t("payment-address-3")}</div>
              </li>
              <li>{t("common:registration-form.description-6")}</li>
              <li>{t("common:registration-form.description-7")}</li>
            </ul>
          </div>
        </div>
        {/* <div className="row mt-3 pt-3">
                    <div className="col">
                        <RegistrationForm />
                    </div>
                </div> */}
        <hr />
        <div className="row">
          <div className="col-12 text-center">
            <h2>
              <strong>{t("common:membership-form.title")}</strong>
            </h2>
          </div>
        </div>
        <div className="row mt-3 pt-3">
          <div className="col-12 text-center col-lg-3">
            <a href={`${IMAGE_BASE_URL}/registration/membership-registration-form.pdf`}>
              <img
                src={printableRegistrationForm}
                alt="Download registration form"
                title="Download registration form"
              ></img>
            </a>
          </div>
          <div className="col-12 col-lg-9">
            <h3>{t("common:description")}</h3>
            <ul>
              <li>{t("common:membership-form.description-1")}</li>
              <li>{t("common:membership-form.description-2")}</li>
              <li>{t("common:membership-form.description-3")}</li>
            </ul>
            <h3 className="mb-3">{t("common:membership-form.payment-information")}</h3>
            <div className="row border-bottom-0">
              <div className="col-4 border text-center">{t("common:membership-fee")}</div>
              <div className="col-4 border text-center">{t("common:gst")}</div>
              <div className="col-4 border text-center">{t("common:total")}</div>
            </div>
            <div className="row border-top-0">
              <div className="col-4 border text-center">$20.00</div>
              <div className="col-4 border text-center">$1.00</div>
              <div className="col-4 border text-center">$21.00</div>
            </div>
            <ul className="mt-3">
              <li>
                <div>{t("common:membership-form.address-1")}</div>
                <div>{t("common:membership-form.address-2")}</div>
              </li>
              <li>{t("common:membership-form.description-6")}</li>
              <li>{t("common:membership-form.description-7")}</li>
            </ul>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-12 text-center">
            <h2>
              <strong>{t("common:figure-skating-registration-form.title")}</strong>
            </h2>
          </div>
        </div>
        <div className="row mt-3 pt-3">
          <div className="col-12 text-center col-lg-3">
            <a href={`${IMAGE_BASE_URL}/registration/figure-skating-registration-form.pdf`}>
              <img
                src={printableRegistrationForm}
                alt="Download registration form"
                title="Download registration form"
              ></img>
            </a>
          </div>
          <div className="col-12 col-lg-9">
            <h3>{t("common:description")}</h3>
            <ul>
              <li>{t("common:figure-skating-registration-form.description-1")}</li>
              <li>{t("common:figure-skating-registration-form.description-2")}</li>
              <li>{t("common:figure-skating-registration-form.description-3")}</li>
              <li>{t("common:figure-skating-registration-form.description-4")}</li>
            </ul>
            <h3 className="mb-3">{t("common:payment-information")}</h3>
            <div className="row border-bottom-0">
              <div className="col-4 border text-center">
                {t("common:figure-skating-registration-form.ten-lessions")}
              </div>
              <div className="col-4 border text-center">
                {t("common:figure-skating-registration-form.ten-lessions-membership")}
              </div>
              <div className="col-4 border text-center">
                {t("common:figure-skating-registration-form.ten-lessions-early-bird")}
              </div>
            </div>
            <div className="row border-top-0">
              <div className="col-4 border text-center">$400.00</div>
              <div className="col-4 border text-center">$380.00</div>
              <div className="col-4 border text-center">$320.00</div>
            </div>
            <div className="row border-bottom-0">
              <div className="col-4 border text-center">
                {t("common:figure-skating-registration-form.twenty-lessions")}
              </div>
              <div className="col-4 border text-center">
                {t("common:figure-skating-registration-form.twenty-lessions-membership")}
              </div>
              <div className="col-4 border text-center">
                {t("common:figure-skating-registration-form.twenty-lessions-early-bird")}
              </div>
            </div>
            <div className="row border-top-0">
              <div className="col-4 border text-center">$700.00</div>
              <div className="col-4 border text-center">$665.00</div>
              <div className="col-4 border text-center">$560.00</div>
            </div>
            <ul className="mt-3">
              {/* <li>
                                <div>{t('common:membership-form.address-1')}</div>
                                <div>{t('common:membership-form.address-2')}</div>
                            </li> */}
              <li>{t("common:membership-form.description-6")}</li>
              <li>{t("common:membership-form.description-7")}</li>
            </ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RegistrationPage;
