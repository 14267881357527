import React from "react";
import { useTranslation } from "react-i18next";

import NavBar from "../NavBar/NavBar";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import { IMAGE_BASE_URL } from "../../config/Config";

const photo_files = Array.from(Array(16), (x, i) => i + 1).map(
  (num) => `hockey_equipments_consulting_service_${num.toString().padStart(3, "0")}.jpg`,
);

const HockeyEquipmentsConsultingService = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <NavBar navBarShrink={"navbar-shrink"} />
      <div className="main-content container">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/services">{t("common:services")}</Link>
            </li>
            <li className="breadcrumb-item" aria-current="page">
              {t("common:hockey-equipments-consulting-service")}
            </li>
          </ol>
        </nav>
        <div className="row">
          <div className="col-12">
            <h2 className="text-center">{t("common:hockey-equipments-consulting-service")}</h2>
            <p>{t("common:hockey-equipments-consulting-service-description")}</p>
          </div>
        </div>
        <section id="hockey-equipments-consulting-service-videos">
          <div className="container">
            <div className="section-heading text-center">
              <h2>{t("common:videos")}</h2>
              <hr />
            </div>
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <ReactPlayer url={`https://www.youtube.com/watch?v=P4YzAR92djo`} controls={true} />
              </div>
            </div>
          </div>
        </section>
        <section id="hockey-equipments-consulting-service-photos">
          <div className="container">
            <div className="section-heading text-center">
              <h2>{t("common:photos")}</h2>
              <hr />
            </div>
            <div className="row">
              <div className="col-12 text-center">
                {photo_files.map((photo_file) => (
                  <img
                    src={`${IMAGE_BASE_URL}/hockey_equipments_consulting_service/${photo_file}`}
                    className="img-fluid pb-3"
                    alt={`${photo_file}`}
                  />
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};

export default HockeyEquipmentsConsultingService;
