import React from "react";
import { useTranslation } from "react-i18next";

import "./style.css";

import NavBar from "../NavBar/NavBar";

interface QA {
  question: string;
  answer: string;
}

const FaqPage = () => {
  const { t } = useTranslation();
  const faqs = t("data:faq", { returnObjects: true }) as QA[];

  return (
    <React.Fragment>
      <NavBar navBarShrink={"navbar-shrink"} />
      <div className="main-content container">
        <h2 className="text-center mb-3 pb-3">{t("common:frequently-asked-questions")}</h2>
        <div className="accordion" id="accordionFAQ">
          {faqs.map((faq, index) => (
            <div className="card mb-3" key={index}>
              <div
                className="card-header collapsed"
                id={`heading${index}`}
                data-toggle="collapse"
                data-target={`#collapse${index}`}
                aria-expanded="false"
                aria-controls={`collapse${index}`}
              >
                <span className="card-title ">{faq.question}</span>
              </div>
              <div
                id={`collapse${index}`}
                className="card-body collapse"
                aria-labelledby={`heading${index}`}
                data-parent={`#accordionFAQ`}
              >
                {faq.answer}
              </div>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default FaqPage;
