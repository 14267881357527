import React from "react";

type AddressProps = {
  name: string;
  address: string;
  city: string;
  province: string;
  postal_code: string;
  telephone: string;
  email: string;
};

const Address = ({ name, address, city, province, postal_code, telephone, email }: AddressProps) => {
  return (
    <address>
      <div className="row">
        <div className="col-1 text-muted mb-3">
          <i className="fas fa-home mr-3" />
        </div>
        <div className="col-11 text-muted mb-3">{name}</div>
      </div>
      <div className="row">
        <div className="col-1 text-muted mb-3">
          <i className="fas fa-map-marker mr-3" />
        </div>
        <div className="col-11 text-muted mb-3">
          <span>{address}</span>
          <br />
          <span>{`${city}, ${province} ${postal_code}`}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-1 text-muted mb-3">
          <i className="fas fa-phone-alt mr-3" />
        </div>
        <div className="col-11 text-muted mb-3">
          <a href={`tel: ${telephone}`}>{telephone}</a>
        </div>
      </div>
      <div className="row">
        <div className="col-1 text-muted mb-3">
          <i className="fas fa-envelope mr-3" />
        </div>
        <div className="col-11 text-muted mb-3">
          <a href={`mailto: ${email}`}>{email}</a>
        </div>
      </div>
    </address>
  );
};

export default Address;
