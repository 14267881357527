import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

import "./style.css";

type FooterProps = {
  companyName: string;
  address: string;
  city: string;
  province: string;
  postal_code: string;
  telephone: string;
  email: string;
};

const Footer = ({ companyName, address, city, province, postal_code, telephone, email }: FooterProps) => {
  const { t } = useTranslation();
  return (
    <footer className="footer text-center">
      <div className="row">
        <div className="col-12 text-center">
          <h5>{t("common:contact-us")}</h5>
          <p>{companyName}</p>
          <div>{address}</div>
          <div>
            {city}, {province} {postal_code}
          </div>
          <div>{telephone}</div>
          <p>{email}</p>
        </div>
      </div>
      <span className="copyright ml-3">{`@ ${moment().format("YYYY")} ${t("common:copyright")}`}</span>
    </footer>
  );
};

export default Footer;
