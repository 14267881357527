import React from "react";
import { useTranslation } from "react-i18next";

import NavBar from "../NavBar/NavBar";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import { IMAGE_BASE_URL } from "../../config/Config";

const photo_files = Array.from(Array(21), (x, i) => i + 1).map(
  (num) => `skills_development_${num.toString().padStart(3, "0")}.jpg`,
);

photo_files.push("2023-spring-camp-001.jpg");
photo_files.push("2023-spring-camp-002.jpg");
photo_files.push("2023-spring-camp-004.jpg");

photo_files.push("2024-new-year-training-camp-001.jpg");
photo_files.push("2024-new-year-training-camp-002.jpg");
photo_files.push("2024-new-year-training-camp-003.jpg");

const SkillsDevelopment = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <NavBar navBarShrink={"navbar-shrink"} />
      <div className="main-content container">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/services">{t("common:services")}</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/services/hockey-training">{t("common:hockey-training")}</Link>
            </li>
            <li className="breadcrumb-item" aria-current="page">
              {t("common:skills-development")}
            </li>
          </ol>
        </nav>
        <div className="row">
          <div className="col-12">
            <h2 className="text-center">{t("common:skills-development")}</h2>
            <p>{t("common:skills-development-description")}</p>
          </div>
        </div>
        <section id="skills-development-videos">
          <div className="container">
            <div className="section-heading text-center">
              <h2>{t("common:videos")}</h2>
              <hr />
            </div>
            <div className="row">
              <div className="col-12 col-md-6 d-flex justify-content-center mt-3 mb-3">
                <ReactPlayer url={"https://youtu.be/15A-YMtx-WQ"} controls={true} />
              </div>
              <div className="col-12 col-md-6 d-flex justify-content-center mt-3 mb-3">
                <ReactPlayer url={"https://youtu.be/ZOC3xHP7ck8"} controls={true} />
              </div>
              <div className="col-12 col-md-6 d-flex justify-content-center mt-3 mb-3">
                <ReactPlayer url={"https://youtu.be/k-cUIsQcWk4"} controls={true} />
              </div>
              <div className="col-12 col-md-6 d-flex justify-content-center mt-3 mb-3">
                <ReactPlayer url={"https://youtu.be/vUgpB8U1sj8"} controls={true} />
              </div>
              <div className="col-12 col-md-6 d-flex justify-content-center mt-3 mb-3">
                <ReactPlayer url={"https://youtu.be/n2ntu-SN45c"} controls={true} />
              </div>
              <div className="col-12 col-md-6 d-flex justify-content-center mt-3 mb-3">
                <ReactPlayer url={"https://youtu.be/ljMcgQ3_mJo"} controls={true} />
              </div>
              <div className="col-12 col-md-6 d-flex justify-content-center mt-3 mb-3">
                <ReactPlayer url={`https://youtu.be/5JVvR5TWpJg`} controls={true} />
              </div>
              <div className="col-12 col-md-6 d-flex justify-content-center mt-3 mb-3">
                <ReactPlayer url={"https://youtu.be/QyxMgof1ksA"} controls={true} />
              </div>
              <div className="col-12 col-md-6 d-flex justify-content-center mt-3 mb-3">
                <ReactPlayer url={`https://www.youtube.com/watch?v=joMmZMBpTQY`} controls={true} />
              </div>
              <div className="col-12 col-md-6 d-flex justify-content-center mt-3 mb-3">
                <ReactPlayer url={`https://youtu.be/3vZbny4mKn4`} controls={true} />
              </div>
              <div className="col-12 col-md-6 d-flex justify-content-center mt-3 mb-3">
                <ReactPlayer url={`https://www.youtube.com/watch?v=rlRW8UTHxr8`} controls={true} />
              </div>
              <div className="col-12 col-md-6 d-flex justify-content-center mt-3 mb-3">
                <ReactPlayer url={`https://www.youtube.com/watch?v=2Sjq2H-y7Yw`} controls={true} />
              </div>
              <div className="col-12 col-md-6 d-flex justify-content-center mt-3 mb-3">
                <ReactPlayer url={`https://www.youtube.com/watch?v=QGAmf0ByK_0`} controls={true} />
              </div>
              <div className="col-12 col-md-6 d-flex justify-content-center mt-3 mb-3">
                <ReactPlayer url={`https://www.youtube.com/watch?v=EC8BJUOfQhU`} controls={true} />
              </div>
            </div>
          </div>
        </section>
        <section id="skills-development-photos">
          <div className="container">
            <div className="section-heading text-center">
              <h2>{t("common:photos")}</h2>
              <hr />
            </div>
            <div className="row">
              <div className="col-12 text-center">
                {photo_files.map((photo_file) => (
                  <img
                    key={photo_file}
                    src={`${IMAGE_BASE_URL}/skills_development/${photo_file}`}
                    className="img-fluid pb-3"
                    alt={`${photo_file}`}
                  />
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};

export default SkillsDevelopment;
