import React, { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import Footer from "./components/Footer/Footer";
import Main from "./components/Main/Main";
import { AppContext } from "./AppContext";

import company from "./data/company.json";

const Loader = () => {
  return <div>Loading...</div>;
};

const App = () => {
  return (
    <Suspense fallback={<Loader />}>
      <AppContext.Provider value={{}}>
        <Router>
          <Main />
          <Footer
            companyName={company.name}
            address={company.address}
            city={company.city}
            province={company.province}
            postal_code={company.postal_code}
            telephone={company.telephone}
            email={company.email}
          />
        </Router>
      </AppContext.Provider>
    </Suspense>
  );
};

export default App;
