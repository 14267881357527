import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import "./style.css";
import { IMAGE_BASE_URL } from "../../config/Config";

const Services = () => {
  const { t } = useTranslation();
  return (
    <section className="features" id="services">
      <div className="container">
        <div className="section-heading text-center">
          <h2>{t("common:featured-services")}</h2>
          <hr />
        </div>
        {/* <ServiceCardList services={t('data:services', {returnObjects: true})} />           */}
        <div className="row mt-3">
          <div className="col-12 col-md-6 col-lg-4 mt-3 mb-3">
            <Link
              to={{ pathname: `/services/hockey-training` }}
              className="mt-auto card-link"
              style={{ cursor: "pointer" }}
            >
              <div className="card clickable" style={{ height: "520px" }}>
                <img
                  src={`${IMAGE_BASE_URL}/skills_development/2023-spring-camp-002.jpg`}
                  className="card-img-top"
                  alt={`hockey-training`}
                  style={{ height: "250px" }}
                />
                <div className="card-body d-flex flex-column">
                  <h3 className="card-title text-center">{t("common:hockey-training")}</h3>
                  <p className="card-text">{t("common:hockey-training-description")}</p>
                  <i className="fas fa-chevron-circle-right mr-3">{t("common:learn-more")}...</i>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-12 col-md-6 col-lg-4 mt-3 mb-3">
            <Link
              to={{ pathname: `/services/off-ice-training` }}
              className="mt-auto card-link"
              style={{ cursor: "pointer" }}
            >
              <div className="card clickable" style={{ height: "520px" }}>
                <img
                  src={`${IMAGE_BASE_URL}/off_ice_training/off_ice_training_007.jpg`}
                  className="card-img-top"
                  alt={`off-ice-training`}
                  style={{ height: "250px" }}
                />
                <div className="card-body d-flex flex-column">
                  <h3 className="card-title text-center">{t("common:off-ice-training")}</h3>
                  <p className="card-text">{t("common:off-ice-training-description")}</p>
                  <i className="fas fa-chevron-circle-right mr-3">{t("common:learn-more")}...</i>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-12 col-md-6 col-lg-4 mt-3 mb-3">
            <Link
              to={{ pathname: `/services/figure-skating` }}
              className="mt-auto card-link"
              style={{ cursor: "pointer" }}
            >
              <div className="card clickable" style={{ height: "520px" }}>
                <img
                  src={`${IMAGE_BASE_URL}/figure_skating/figure_skating_001.jpg`}
                  className="card-img-top"
                  alt={`skills-development`}
                  style={{ height: "250px" }}
                />
                <div className="card-body d-flex flex-column">
                  <h3 className="card-title text-center">{t("common:figure-skating")}</h3>
                  <p className="card-text">{t("common:figure-skating-description")}</p>
                  <i className="fas fa-chevron-circle-right mr-3">{t("common:learn-more")}...</i>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-12 col-md-6 col-lg-4 mt-3 mb-3">
            <Link
              to={{ pathname: `/services/hockey-equipments-consulting-service` }}
              className="mt-auto card-link"
              style={{ cursor: "pointer" }}
            >
              <div className="card clickable" style={{ height: "520px" }}>
                <img
                  src={`${IMAGE_BASE_URL}/hockey_equipments_consulting_service/hockey_equipments_consulting_service_005.jpg`}
                  className="card-img-top"
                  alt={`hockey-equipments`}
                  style={{ height: "250px" }}
                />
                <div className="card-body d-flex flex-column">
                  <h3 className="card-title text-center">{t("common:hockey-equipments-consulting-service")}</h3>
                  <p className="card-text">{t("common:hockey-equipments-consulting-service-description")}</p>
                  <i className="fas fa-chevron-circle-right mr-3">{t("common:learn-more")}...</i>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-12 col-md-6 col-lg-4 mt-3 mb-3">
            <Link
              to={{ pathname: `/services/hockey-exchange` }}
              className="mt-auto card-link"
              style={{ cursor: "pointer" }}
            >
              <div className="card clickable" style={{ height: "520px" }}>
                <img
                  src={`${IMAGE_BASE_URL}/international_hockey_exchange/international_hockey_exchange_008.jpg`}
                  className="card-img-top"
                  alt={`international hockey exchange`}
                  style={{ height: "250px" }}
                />
                <div className="card-body d-flex flex-column">
                  <h3 className="card-title text-center">{t("common:hockey-exchange")}</h3>
                  <p className="card-text">{t("common:hockey-exchange-description")}</p>
                  <i className="fas fa-chevron-circle-right mr-3">{t("common:learn-more")}...</i>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
