import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { connectRouter } from "connected-react-router";
import communicationReducer from "./communication_reducer";

const rootReducer = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    form: formReducer,
    communication: communicationReducer,
  });

export default rootReducer;
