import { SEND_CONTACT_FORM, STATIC_ERROR, CLEAR_MESSAGE } from "../actions/types";

const INITIAL_STATE = { message: "", error: "" };

export default function (state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case SEND_CONTACT_FORM:
      return { ...state, message: action.payload };
    case STATIC_ERROR:
      return { ...state, error: action.payload };
    case CLEAR_MESSAGE:
      return { ...state, message: undefined };
    default:
      return state;
  }
}
