import React from "react";
import { useTranslation } from "react-i18next";

import { Service } from "./Interfaces";

import NavBar from "../NavBar/NavBar";
import { Link } from "react-router-dom";
import { IMAGE_BASE_URL } from "../../config/Config";

const HockeyTraining = () => {
  const { t } = useTranslation();
  const data = t("data:hockey-training", { returnObjects: true }) as Service;

  return (
    <React.Fragment>
      <NavBar navBarShrink={"navbar-shrink"} />
      <div className="main-content container">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/services">{t("common:services")}</Link>
            </li>
            <li className="breadcrumb-item">{t("common:hockey-training")}</li>
          </ol>
        </nav>
        <div className="row">
          <div className="col-12">
            <h2 className="text-center">{`${data.name}`}</h2>
            <p className="card-text">{`${data.description}`}</p>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 col-md-6 col-lg-4 mt-3 mb-3">
            <Link to="/services/hockey-training/learn-to-play" style={{ cursor: "pointer" }}>
              <div className="card clickable">
                <img
                  src={`${IMAGE_BASE_URL}/learn_to_play/learn_to_play_013.jpg`}
                  className="card-img-top"
                  alt={`learn-to-play`}
                  style={{ height: "250px" }}
                />
                <div className="card-body d-flex flex-column">
                  <h2 className="card-title text-center">{t("common:learn-to-play")}</h2>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-12 col-md-6 col-lg-4 mt-3 mb-3">
            <Link to="/services/hockey-training/skills-development" style={{ cursor: "pointer" }}>
              <div className="card clickable">
                <img
                  src={`${IMAGE_BASE_URL}/skills_development/skills_development_013.jpg`}
                  className="card-img-top"
                  alt={`skills-development`}
                  style={{ height: "250px" }}
                />
                <div className="card-body d-flex flex-column">
                  <h2 className="card-title text-center">{t("common:skills-development")}</h2>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-12 col-md-6 col-lg-4 mt-3 mb-3">
            <Link to="/services/hockey-training/tactics-development" style={{ cursor: "pointer" }}>
              <div className="card clickable">
                <img
                  src={`${IMAGE_BASE_URL}/tactics_development/tactics_development.jpg`}
                  className="card-img-top"
                  alt={`tactics-development`}
                  style={{ height: "250px" }}
                />
                <div className="card-body d-flex flex-column">
                  <h2 className="card-title text-center">{t("common:tactics-development")}</h2>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HockeyTraining;
