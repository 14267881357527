import React from "react";
import { useTranslation } from "react-i18next";

import "./style.css";

import NavBar from "../NavBar/NavBar";
import { Link } from "react-router-dom";
import { IMAGE_BASE_URL } from "../../config/Config";

import qr_code from "./qr-code.jpg";

const ResourcesPage = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <NavBar navBarShrink={"navbar-shrink"} />
      <div className="main-content container">
        <h2 className="text-center mb-3 pb-3">{t("common:resources")}</h2>
        <div className="row mt-3">
          <div className="col-12 col-md-6 col-lg-3 mt-3 mb-3">
            <Link to="/resources/ice-hockey-rules">
              <div className="card clickable" style={{ cursor: "pointer" }}>
                <img
                  src={`${IMAGE_BASE_URL}/hockey_rules/hockey-rules.jpg`}
                  className="card-img-top"
                  alt={`hockey-rules`}
                  style={{ height: "250px" }}
                />
                <div className="card-body d-flex flex-column">
                  <h2 className="card-title text-center">{t("common:hockey-rules")}</h2>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-12 col-md-6 col-lg-3 mt-3 mb-3">
            <Link to="/resources/hockey-equipments">
              <div className="card clickable" style={{ cursor: "pointer" }}>
                <img
                  src={`${IMAGE_BASE_URL}/hockey_equipments/hockey-equipments.jpg`}
                  className="card-img-top"
                  alt={`hockey-rules`}
                  style={{ height: "250px" }}
                />
                <div className="card-body d-flex flex-column">
                  <h2 className="card-title text-center">{t("common:hockey-equipments")}</h2>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-12 col-md-6 col-lg-3 mt-3 mb-3">
            <div className="card clickable" style={{ cursor: "pointer" }}>
              <img
                src={`${IMAGE_BASE_URL}/affiliated_sites/talkers.png`}
                className="card-img-top"
                alt={`hockey-rules`}
                style={{ height: "250px" }}
              />
              <div className="card-body d-flex flex-column">
                <h2 className="card-title text-center">
                  <a href="https://www.parkerenglishdrills.com/" rel="noopener noreferrer" target="_blank">
                    Parker English Drills
                  </a>
                </h2>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3 mt-3 mb-3">
            <div className="card clickable" style={{ cursor: "pointer" }}>
              <img
                src={qr_code}
                className="card-img-top"
                alt={`mtt56sports wechat office account`}
                style={{ height: "250px" }}
              />
              <div className="card-body d-flex flex-column">
                <h2 className="card-title text-center">{t("common:wechat-office-account")}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ResourcesPage;
