import React from "react";
import { useTranslation } from "react-i18next";

import "./style.css";
import { Link } from "react-router-dom";

interface QA {
  question: string;
  answer: string;
}

const Faq = () => {
  const { t } = useTranslation();
  const faqs = t("data:faq", { returnObjects: true }) as QA[];
  return (
    <section className="faqs" id="faqs">
      <div className="container">
        <div className="section-heading text-center">
          <h2>{t("common:frequently-asked-questions")}</h2>
          <hr />
        </div>
        <div className="accordion" id="accordionFAQ">
          {faqs.slice(0, 3).map((faq, index) => (
            <div className="card mb-3" key={index}>
              <div
                className="card-header collapsed"
                id={`heading${index}`}
                data-toggle="collapse"
                data-target={`#collapse${index}`}
                aria-expanded="false"
                aria-controls={`collapse${index}`}
              >
                <span className="card-title">{faq.question}</span>
              </div>
              <div
                id={`collapse${index}`}
                className="card-body collapse"
                aria-labelledby={`heading${index}`}
                data-parent={`#accordionFAQ`}
              >
                {faq.answer}
              </div>
            </div>
          ))}
        </div>
        <Link to={{ pathname: `/faq` }} className="mt-3">
          <i className="fas fa-chevron-circle-right mr-1" />
          {t("common:learn-more")}...
        </Link>
      </div>
    </section>
  );
};

export default Faq;
