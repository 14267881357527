import React from "react";
import { Form, IFields, required, isEmail, maxLength, isTelephone } from "../Common/Form";
import { Field } from "../Common/Field";

import { useTranslation } from "react-i18next";

export const ContactUsForm: React.FC = () => {
  const { t } = useTranslation();
  const fields: IFields = {
    senderName: {
      id: "senderName",
      label: t("common:name"),
      required: true,
      validations: [{ rule: required }, { rule: maxLength, args: 50 }],
    },
    senderEmail: {
      id: "senderEmail",
      label: t("common:email"),
      required: true,
      validations: [{ rule: required }, { rule: isEmail }],
    },
    telephone: {
      id: "telephone",
      label: t("common:telephone"),
      required: false,
      validations: [{ rule: isTelephone }],
    },
    subject: {
      id: "subject",
      label: t("common:subject"),
      required: true,
      validations: [{ rule: required }, { rule: maxLength, args: 100 }],
    },
    message: {
      id: "message",
      label: t("common:message"),
      editor: "multilinetextbox",
      validations: [{ rule: maxLength, args: 10000 }],
    },
  };

  const predefinedValues = {
    receiverEmail: process.env.REACT_APP_EMAIL,
  };

  return (
    <div className="jumbotron">
      <Form
        action={`${process.env.REACT_APP_API_BASE}/api/eater/communication/contact`}
        fields={fields}
        predefinedValues={predefinedValues}
        submitButtonCaption={t("common:contact-us")}
        render={() => (
          <React.Fragment>
            <div className="alert alert-info text-center" role="alert">
              {t("common:contact-form.information")}
            </div>
            <Field {...fields.senderName} />
            <Field {...fields.senderEmail} />
            <Field {...fields.telephone} />
            <Field {...fields.subject} />
            <Field {...fields.message} />
          </React.Fragment>
        )}
      />
    </div>
  );
};
