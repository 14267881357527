import React from "react";
import { IMAGE_BASE_URL } from "../../config/Config";
import "./style.css";

const Hero = () => {
  return (
    <div className="hero-image">
      <div id="carouselHero" className="carousel slide" data-ride="carousel">
        <ol className="carousel-indicators">
          <li data-target="#carouselHero" data-slide-to="0" className="active"></li>
          <li data-target="#carouselHero" data-slide-to="1" className="active"></li>
          <li data-target="#carouselHero" data-slide-to="2" className="active"></li>
          <li data-target="#carouselHero" data-slide-to="3" className="active"></li>
        </ol>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              className="d-block w-100 img-fluid"
              src={`${IMAGE_BASE_URL}/skills_development/2023-spring-camp-001.jpg`}
              alt="2023 spring camp 1"
            />
          </div>
          <div className="carousel-item">
            <img
              className="d-block w-100 img-fluid"
              src={`${IMAGE_BASE_URL}/skills_development/skills_development_022.jpg`}
              alt="2021 skills development camp 1"
            />
          </div>
          <div className="carousel-item">
            <img
              className="d-block w-100 img-fluid"
              src={`${IMAGE_BASE_URL}/skills_development/2022-summer-camp-003.jpg`}
              alt="2023 spring camp 4"
            />
          </div>
          <div className="carousel-item">
            <img className="d-block w-100 img-fluid" src={`${IMAGE_BASE_URL}/hero/hero_000.jpg`} alt="hero" />
          </div>
        </div>
        <a className="carousel-control-prev" href="#carouselHero" role="button" data-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="sr-only">Previous</span>
        </a>
        <a className="carousel-control-next" href="#carouselHero" role="button" data-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="sr-only">Next</span>
        </a>
      </div>
    </div>
  );
};

export default Hero;
