import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Trans } from "react-i18next";
import i18next from "i18next";

import { IFieldProps } from "./Field";

export interface IFields {
  [key: string]: IFieldProps;
}

interface IPredefinedValues {
  [key: string]: any;
}

interface IFormProps {
  action: string;

  fields: IFields;

  predefinedValues?: IPredefinedValues;

  submitButtonCaption?: string;

  render: () => React.ReactNode;
}

export interface IValues {
  [key: string]: any;
}

export interface IErrors {
  [key: string]: string[];
}

export interface IFormState {
  values: IValues;
  errors: IErrors;
  submitSuccess?: boolean;
}

export interface IFormContext extends IFormState {
  setValues: (values: IValues) => void;

  validate: (fieldName: string) => void;
}

export const FormContext = React.createContext<IFormContext | undefined>(undefined);

const successNotification = () => {
  return (
    <div className="alert alert-info text-center" role="alert">
      <Trans i18nKey="valiadtion:success" />
    </div>
  );
};

const notify = () => toast.success(successNotification);

export class Form extends React.Component<IFormProps, IFormState> {
  constructor(props: IFormProps) {
    super(props);

    const errors: IErrors = {};
    const values: IValues = props.predefinedValues ? { ...props.predefinedValues } : {};
    this.state = {
      errors,
      values,
    };
  }

  private setValues = (values: IValues) => {
    this.setState({ values: { ...this.state.values, ...values } });
  };

  private haveErrors(errors: IErrors) {
    let haveError: boolean = false;
    Object.keys(errors).forEach((key: string) => {
      haveError = errors[key].length > 0;
    });
    return haveError;
  }

  private handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    if (this.validateForm()) {
      const submitSuccess: boolean = await this.submitForm();
      this.setState({ submitSuccess });
    }
  };

  private validateForm(): boolean {
    const errors: IErrors = {};
    Object.keys(this.props.fields).forEach((fieldName: string) => {
      errors[fieldName] = this.validate(fieldName);
    });
    this.setState({ errors });
    return !this.haveErrors(errors);
  }

  private clearForm(): void {
    Object.keys(this.props.fields).forEach((fieldName: string) => {
      this.props.fields[fieldName].value = "";
    });
  }

  private async submitForm(): Promise<boolean> {
    try {
      const response = await fetch(this.props.action, {
        method: "post",
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
        }),
        body: JSON.stringify(this.state.values),
      });

      if (response.status === 200) {
        notify();
        this.clearForm();
      }

      if (response.status === 400) {
        /* Map the validation errors to IErrors */
        let responseBody: any;
        responseBody = await response.json();
        const errors: IErrors = {};
        Object.keys(responseBody).forEach((key: string) => {
          // For ASP.NET core, the field names are in title case - so convert to camel case
          const fieldName = key.charAt(0).toLowerCase() + key.substring(1);
          errors[fieldName] = responseBody[key];
        });
        this.setState({ errors });
      }

      return response.ok;
    } catch (ex) {
      return false;
    }
  }

  private validate = (fieldName: string): string[] => {
    let newError: string[] = [];

    if (this.props.fields[fieldName] && this.props.fields[fieldName].validations) {
      this.props.fields[fieldName].validations!.forEach((validation) => {
        const validationMessage = validation.rule(this.state.values, fieldName, validation.args);
        if (validationMessage) {
          newError.push(validationMessage);
        }
      });
    }

    if (newError.length > 0) {
      // this.state.errors[fieldName] = newError;
      this.setState({
        errors: { ...this.state.errors, [fieldName]: newError },
      });
    }

    return newError;
  };

  public render() {
    const { submitSuccess, errors } = this.state;
    const context: IFormContext = {
      ...this.state,
      setValues: this.setValues,
      validate: this.validate,
    };

    return (
      <FormContext.Provider value={context}>
        <form onSubmit={this.handleSubmit} noValidate={true}>
          <div className="container">
            {this.props.render()}

            <div className="form-group text-center">
              <button type="submit" className="btn btn-primary btn-lg" disabled={this.haveErrors(errors)}>
                {this.props.submitButtonCaption ? this.props.submitButtonCaption : "Submit"}
              </button>
            </div>
            {submitSuccess && <ToastContainer />}
            {submitSuccess === false && !this.haveErrors(errors) && (
              <div className="alert alert-danger text-center" role="alert">
                <Trans i18nKey="validation:unexpected-error" />
              </div>
            )}
            {submitSuccess === false && this.haveErrors(errors) && (
              <div className="alert alert-danger text-center" role="alert">
                <Trans i18nKey="validation:invalid" />
              </div>
            )}
          </div>
        </form>
      </FormContext.Provider>
    );
  }
}

export const required = (values: IValues, fieldName: string): string =>
  values[fieldName] === undefined || values[fieldName] === null || values[fieldName] === ""
    ? i18next.t("validation:required")
    : "";

export const isEmail = (values: IValues, fieldName: string): string =>
  values[fieldName] && values[fieldName].search(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
    ? i18next.t("validation:email")
    : "";

export const isTelephone = (values: IValues, fieldName: string): string =>
  values[fieldName] && values[fieldName].search(/\d{3}-\d{3}-\d{4}|\d{10}/) === -1
    ? i18next.t("validation:telephone")
    : "";

export const maxLength = (values: IValues, fieldName: string, length: number): string =>
  values[fieldName] && values[fieldName].length > length ? i18next.t("validation:max-length", { length: length }) : "";
