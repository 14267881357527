import React from "react";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <div className="">
      <h1>{t(`common:404-page-not-found`)}</h1>
      <p>{t("common:sorry-page-not-found")}</p>
    </div>
  );
};

export default NotFound;
