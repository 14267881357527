import React from "react";
import { useTranslation } from "react-i18next";

import NavBar from "../NavBar/NavBar";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import { IMAGE_BASE_URL } from "../../config/Config";

// const photo_files = Array.from(Array(1), (x, i) => i).map(num => `off_ice_training_${num.toString().padStart(3, '0')}.jpg`);
const photo_files = [
  "off_ice_training_007.jpg",
  "off_ice_training_008.jpg",
  "off_ice_training_009.jpg",
  "off_ice_training_010.jpg",
  "off_ice_training_011.jpg",
  "off_ice_training_012.jpg",
  "off_ice_training_000.jpg",
];
const OffIceTraining = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <NavBar navBarShrink={"navbar-shrink"} />
      <div className="main-content container">
        <nav aria-label="breadcru;mb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/services">{t("common:services")}</Link>
            </li>
            <li className="breadcrumb-item" aria-current="page">
              {t("common:off-ice-training")}
            </li>
          </ol>
        </nav>
        <div className="row">
          <div className="col-12">
            <h2 className="text-center">{t("common:off-ice-training")}</h2>
            <p>{t("common:off-ice-training-description")}</p>
          </div>
        </div>
        <section id="off-ice-training-videos">
          <div className="container">
            <div className="section-heading text-center">
              <h2>{t("common:videos")}</h2>
              <hr />
            </div>
            <div className="row">
              <div className="col-12 col-md-6 d-flex justify-content-center mt-3 mb-3">
                <ReactPlayer url={`https://www.youtube.com/watch?v=sInii1Xg364`} controls={true} />
              </div>
              <div className="col-12 col-md-6 d-flex justify-content-center mt-3 mb-3">
                <ReactPlayer url={`https://www.youtube.com/watch?v=lyKB8V_oeD4`} controls={true} />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 d-flex justify-content-center mt-3 mb-3">
                <ReactPlayer url={`https://www.youtube.com/watch?v=6EzfDDrMfbE`} controls={true} />
              </div>
              <div className="col-12 col-md-6 d-flex justify-content-center mt-3 mb-3">
                <ReactPlayer url={`https://www.youtube.com/watch?v=QtknnlU3X58`} controls={true} />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 d-flex justify-content-center mt-3 mb-3">
                <ReactPlayer url={`https://www.youtube.com/watch?v=lZ4LcsTqj7o`} controls={true} />
              </div>
              <div className="col-12 col-md-6 d-flex justify-content-center mt-3 mb-3">
                <ReactPlayer url={`https://www.youtube.com/watch?v=6gDIF8_qKag`} controls={true} />
              </div>
            </div>
          </div>
        </section>
        <section id="off-ice-training-photos">
          <div className="container">
            <div className="section-heading text-center">
              <h2>{t("common:photos")}</h2>
              <hr />
            </div>
            <div className="row">
              <div className="col-12 text-center">
                {photo_files.map((photo_file) => (
                  <img
                    key={photo_file}
                    src={`${IMAGE_BASE_URL}/off_ice_training/${photo_file}`}
                    className="img-fluid pb-3"
                    alt={`${photo_file}`}
                  />
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};

export default OffIceTraining;
