import React from "react";

import "./style.css";

import Address from "./Address";
import Map from "./Map";

import company from "../../data/company.json";
import NavBar from "../NavBar/NavBar";
import { ContactUsForm } from "./ContactUsForm";
import { IMAGE_BASE_URL } from "../../config/Config";

const ContactUsPage = () => {
  return (
    <React.Fragment>
      <NavBar navBarShrink={"navbar-shrink"} />
      <div className="main-content container">
        <div className="row">
          <div className="col-12 col-xl-4">
            <img src={`${IMAGE_BASE_URL}/contact_us/contact.jpg`} className="img-fluid pb-3" alt={`contact`} />
            <div className="mt-3 pt-3">
              <Address
                name={company.name}
                address={company.address}
                city={company.city}
                province={company.province}
                postal_code={company.postal_code}
                telephone={company.telephone}
                email={company.email}
              />
            </div>
          </div>
          <div className="col-12 col-xl-8">
            <Map locationName={company.name} latitude={company.latitude} longitude={company.longitude} />
          </div>
        </div>
        <div className="row mt-3 pt-3">
          <div className="col">
            <ContactUsForm />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ContactUsPage;
