import React from "react";
import GoogleMapReact from "google-map-react";

type AnyProps = {
  text: string;
};

const AnyReactComponent = ({ text }: AnyProps) => (
  <span style={{ color: "Red" }}>
    <i className="fas fa-map-marker-alt" /> {text}
  </span>
);

type MapProps = {
  locationName: string;
  latitude: number;
  longitude: number;
};

const Map = ({ locationName, latitude, longitude }: MapProps) => {
  let defaultCenter = { lat: latitude, lng: longitude };
  return (
    <div style={{ height: "450px", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_KEY! }}
        defaultCenter={defaultCenter}
        defaultZoom={15}
      >
        <AnyReactComponent text={locationName} />
      </GoogleMapReact>
    </div>
  );
};

export default Map;
