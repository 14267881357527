import React from "react";
import { useTranslation } from "react-i18next";

import NavBar from "../NavBar/NavBar";
import { Link } from "react-router-dom";

import ReactPlayer from "react-player";
import { IMAGE_BASE_URL } from "../../config/Config";

const photo_files = Array.from(Array(15), (x, i) => i + 1).map(
  (num) => `learn_to_play_${num.toString().padStart(3, "0")}.jpg`,
);

const LearnToPlay = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <NavBar navBarShrink={"navbar-shrink"} />
      <div className="main-content container">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/services">{t("common:services")}</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/services/hockey-training">{t("common:hockey-training")}</Link>
            </li>
            <li className="breadcrumb-item" aria-current="page">
              {t("common:learn-to-play")}
            </li>
          </ol>
        </nav>
        <div className="row">
          <div className="col-12">
            <h2 className="text-center">{t("common:learn-to-play")}</h2>
            <p>{t("common:learn-to-play-description")}</p>
          </div>
        </div>
        <section id="learn-to-play-videos">
          <div className="container">
            <div className="section-heading text-center">
              <h2>{t("common:videos")}</h2>
              <hr />
            </div>
            <div className="row">
              <div className="col-12 col-md-6 d-flex justify-content-center">
                <ReactPlayer url={`https://www.youtube.com/watch?v=WN0yoofwMT4`} controls={true} />
              </div>
              <div className="col-12 col-md-6 d-flex justify-content-center">
                <ReactPlayer url={`https://www.youtube.com/watch?v=GlddksvjwVM`} controls={true} />
              </div>
            </div>
          </div>
        </section>
        <section id="learn-to-play-photos">
          <div className="container">
            <div className="section-heading text-center">
              <h2>{t("common:photos")}</h2>
              <hr />
            </div>
            <div className="row">
              <div className="col-12 text-center">
                {photo_files.map((photo_file) => (
                  <img
                    key={photo_file}
                    src={`${IMAGE_BASE_URL}/learn_to_play/${photo_file}`}
                    className="img-fluid pb-3"
                    alt={`${photo_file}`}
                  />
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};

export default LearnToPlay;
